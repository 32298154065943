<template>
  <ion-page>
    <toolbar redirect="home" :profile="false"></toolbar>
    <ion-content>
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col size-md="6" size-sm="12">
            <div id="header">
                <img src="@/assets/img/sukisan-logo.png" />
            </div>
              <form>
                <div class="input-group">
                  <ion-item lines="none">
                    <ion-icon :icon="mailOutline" slot="start"  class="ion-margin-end"></ion-icon>
                    <ion-input 
                     type="email" 
                     placeholder="Email" 
                     formControlName="email"
                     v-model="state.email"></ion-input>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-icon 
                      :icon="state.lockIcon" 
                      slot="start" 
                      class="ion-margin-end hover-link"
                      @click="state.clearText=!state.clearText"></ion-icon>
                    <ion-input 
                     :type="state.passwordType" 
                     placeholder="Password" 
                     formControlName="password"
                     v-model="state.password"></ion-input>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-icon :icon="personOutline" slot="start"  class="ion-margin-end"></ion-icon>
                    <ion-input 
                     type="text" 
                     placeholder="Display Name" 
                     formControlName="name"
                     v-model="state.name"></ion-input>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-icon :icon="callOutline" slot="start"  class="ion-margin-end"></ion-icon>
                    <ion-input 
                     type="text" 
                     placeholder="Contact No" 
                     formControlName="contactno"
                     v-model="state.contactno"></ion-input>
                  </ion-item>
                </div>
            
                <ion-button 
                  type="button"
                  expand="block" 
                  @click.prevent="register"
                  >Register</ion-button>
                <ion-button 
                  type="button" 
                  expand="block" 
                  class="fblogin-btn" 
                  @click.prevent="loginViaFB">
                  <ion-icon :icon="logoFacebook" slot="start"></ion-icon>
                  Register with Facebook
                </ion-button>                  
              </form>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonButton, IonInput, 
         IonContent, IonCol, IonGrid, IonRow, 
         IonItem, IonIcon, toastController } from '@ionic/vue'
import { defineComponent, reactive, inject, 
         computed
       } from 'vue'
import Toolbar from '@/components/Toolbar.vue'
import { logoFacebook, mailOutline, lockClosedOutline , lockOpenOutline,
         personOutline, callOutline
       } from 'ionicons/icons'
import userStore from '@/store/user'
import { useRouter } from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'


export default defineComponent({
  name: 'Registration',
  components: { IonPage, IonButton, IonInput, 
         IonContent, Toolbar,
         IonItem,
         IonCol, IonGrid, IonRow,
         IonIcon
              },
  setup() {
    const router = useRouter()
    const loadSpin = inject('loadSpinner')
    const state = reactive({
      email: process.env.VUE_APP_DEV_LOGIN || "",
      password:  process.env.VUE_APP_DEV_PWD || "",
      clearText: false,
      name: "",
      contactno: "",
      passwordType: computed(() => {
         return state.clearText ? "text": "password"
      }),
      lockIcon: computed(() => {
         return state.clearText ? lockOpenOutline: lockClosedOutline
      })
    })

    async function openToast(msg, cls, header, pos, color) {
      const toast = await toastController
        .create({
          message: msg,
          header: header,
          position: pos,
          duration: 2000,
          color: color
        })
      return toast.present();
    }

    async function setToken(data) {
      const stat = await userStore.setIdToken(state.name, state.contactno)
      await userStore.setUserDetails(state.name, state.contactNo)
      
      console.log(`stat: ${stat}`)
      router.push('/')
    }

    async function register() {
      loadSpin.status=true
      firebase.auth().createUserWithEmailAndPassword(state.email, state.password)
        .then((user) => {
          // Signed in 
          // ...
          setToken(user)
          loadSpin.status = false
        })
        .catch((error) => {
          // var errorCode = error.code;
          // var errorMessage = error.message;
          // ..
          loadSpin.status=false
          console.log(error)
        })      
    }

    function loginViaFB () {
      const provider = new firebase.auth.FacebookAuthProvider()
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(data => {
          setToken(data)
          loadSpin.status = false
      }).catch(error => {
        // Handle Errors here.
        // var errorCode = error.code;
        openToast(error.message, "", "Error", "middle", "danger" )
        // The email of the user's account used.
        // var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
      });
    }      

    return { 
      state, 
      register, 
      logoFacebook, mailOutline, lockClosedOutline , lockOpenOutline,
      personOutline, callOutline, 
      openToast,
      loginViaFB,
      userStore
      }
  }
})

</script>


<style scoped>

 ion-content {
  --background: #f5f5f3;
  --color: var(--ion-color-primary-text-dark);
}
ion-content #header {
    display: flex;
    flex-direction: column;
    align-items: center;
}
/*
ion-content #header h1 {
    font-weight: 700;
    font-size: 26px;
    color: #000010;
}

ion-content #header img {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    border: 8px solid #ffffff;
}

ion-content {
    --padding-top: 40%;
    --padding-start: 10%;
    --padding-end: 10%;
    __background: url('https://images.unsplash.com/photo-1536431311719-398b6704d4cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80') 0 0/100% 100% no-repeat;
}
  */
.input-group {
    background: #fff;
    border-radius: 13px;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 15px;
}
 
.errors {
    font-size: small;
    color: #fff;
    background: var(--ion-color-danger);
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.fblogin-btn {
  --background: #4267B2;
}

.hover-link:hover {
  cursor: pointer;
}
ion-button {
  --border-radius: 13px;
  --box-shadow: none;
}
</style>
